import { createWebHistory, createRouter } from 'vue-router';
// import routes from './routes';
import routes from './routes/index';
import { roles } from 'src/config/roles-config';
import store from 'src/store';
const router = new createRouter({
  routes: routes,
  history: createWebHistory(),
});

function getOTPAuthValues() {
  try {
    const isOtpSetup = JSON.parse(store.getters.isOtpSetup);
    const require_otp = JSON.parse(store.getters.require_otp);
    return { isOtpSetup, require_otp };
  } catch {
    const isOtpSetup = false;
    const require_otp = false;
    return { isOtpSetup, require_otp };
  }

  // return { isOtpSetup: true, credentialCheckFlag: true };
}

router.beforeEach((to, from, next) => {
  // isSessionExpired();
  const isTokenSet = store.getters.isTokenSet;
  const role = store.getters.role;
  const { isOtpSetup, require_otp } = getOTPAuthValues();
  const isAzureLogin = store.getters.azureLogin;

  if (
    !require_otp &&
    isOtpSetup &&
    isTokenSet &&
    to.path.includes('/reset-password/')
  ) {
    localStorage.clear();
    store.dispatch('changeRoute', [to.name]);
    next();
  }

  if (!isLoginOrSignup(to) && isValidRole(role) && !isTokenSet) {
    store.dispatch('clearState');
  }
  // debugger;

  if (require_otp && isOtpSetup && isTokenSet) {
    store.dispatch('clearState');
  }

  if (
    !isAzureLogin &&
    !require_otp &&
    isOtpSetup &&
    isTokenSet &&
    to.path.includes('otp-selection')
  ) {
    return redirectToAccuratePath(role, next);
  }

  if (
    !isAzureLogin &&
    isTokenSet &&
    !isOtpSetup &&
    !to.path.includes('otp-selection') &&
    !to.path.includes('privacy-policy')
  ) {
    //return next({ name: 'OTP Selection' });
  }

  if (
    !isAzureLogin &&
    !require_otp &&
    isTokenSet &&
    isLoginOrSignup(to) &&
    !to.path.includes('privacy-policy') &&
    !to.path.includes('otp-selection')
  ) {
    return redirectToAccuratePath(role, next);
  }

  if (to.path === '/') {
    return redirectToAccuratePath(role, next);
  }

  const { authorize } = to.meta;

  if (
    !require_otp &&
    isTokenSet &&
    !authorize?.includes(role) &&
    !to.path.includes('privacy-policy') &&
    !to.path.includes('otp-selection') &&
    !to.path.includes('change-password')
  ) {
    return redirectToAccuratePath(role, next);
  }

  store.dispatch('changeRoute', [to.name]);
  next();
});

function redirectToAccuratePath(role, next) {
  switch (role) {
    case roles.admin:
      return next({ name: 'Organizations' });
    case roles.user:
    case roles.org_user:
    case roles.support_user:
      return next({ name: 'Home' });
    case roles.labeler:
      return next({ name: 'Tasks Requested' });
    case roles.analytics_user:
      return next({ name: 'Analytics' });

    case roles.doc_user:
      return next({ name: 'User Manuals' });

    default:
      store.dispatch('clearState');
      return next({ path: '/login' });
  }
}

function isSessionExpired() {
  var minutesDifference = calculateTimeDifference();

  if (minutesDifference > 24) {
    store.dispatch('clearState');
  }
}

function calculateTimeDifference() {
  let timestamp = localStorage.getItem('timestamp');
  let currentDate = new Date();
  let lastLogin = new Date(timestamp);

  let difference = currentDate - lastLogin;
  let minutesDifference = Math.floor(difference / 1000 / 60 / 60);
  // let minutesDifference = Math.floor(difference / 1000);
  return minutesDifference;
}

function isLoginOrSignup(to) {
  return [
    '/login',
    '/signup',
    '/privacy-policy',
    '/resend-verification',
    '/verify-email/',
    // '/otp-selection',
    // '/verify-otp',
    '/forgot-password',
    '/reset-password',
  ].some((url) => to.path.includes(url));
}

function isValidRole(role) {
  if (!Object.values(roles).includes(role)) {
    store.dispatch('clearState');
    return false;
  }
  return true;
}

export default router;
